import axiosInstance from "./axiosInstance"

export interface JobType {
  id: number
  name: string
  key: JobKeyType
}

export type JobKeyType = "osteo" | "kine" | "podo" | "other"

export const getAllJobs = async () => {
  const { data } = await axiosInstance.get<JobType[]>(`/job/all`)
  return data
}
