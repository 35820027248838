import {
  Box,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { AcademieOsteoType } from "api/academie"
import { JobKeyType } from "api/job"
import { CurrentScope, useAppContext } from "AppContext"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import appointmentsQueryKeys from "constants/queryKeys/appointments"
import { isBefore } from "date-fns"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import OsteoFilter from "pages/RecentAppointments/components/OsteoFilter"
import { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import {
  AppointmentSimplifiedType,
  getAppointments,
} from "../../api/appointments"
import AButton from "../../components/AButton"
import NoAppointmentYet from "./components/NoAppointmentYet"

// HELPERS
const getAppointmentsParams = (
  currentScope: CurrentScope | null,
  page: number,
  osteos?: number[]
) => {
  if (currentScope?.type === "academy") {
    return {
      osteos,
      page,
      ...getCurrentScopeParams(currentScope),
    }
  }
  if (currentScope?.type === "center") {
    return {
      osteos,
      page,
      ...getCurrentScopeParams(currentScope),
    }
  }
  return { page }
}

// COMPONENTS
const RecentAppointments = () => {
  const { currentScope } = useAppContext()

  const [page, setPage] = useState(1)
  const [selectedOsteos, setSelectedOsteos] = useState<AcademieOsteoType[]>([])
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const selectedOsteoIds =
    selectedOsteos.length === 0 ? undefined : selectedOsteos.map(({ id }) => id)

  const { data, isLoading } = useQuery(
    appointmentsQueryKeys.list(currentScope?.id, selectedOsteoIds, page),
    () =>
      getAppointments(
        getAppointmentsParams(currentScope, page, selectedOsteoIds)
      ),
    {
      retry: retryCollaboratorUnauthorized,
      onError: onCollaboratorUnauthorized,
    }
  )

  const list = data?.results ?? []
  const pageCount = data?.count.totalPage ?? 0

  const formattedList: AppointmentSimplifiedType[] = list.map(
    (appointment) => ({
      ...appointment,
      job: appointment.job ? appointment.job.key : "osteo",
    })
  )

  const isJobOsteo = useJobIsOsteo()

  /**
   * @param job from appointment
   * @returns true if the job is osteo or other
   */
  const isAppointmentOsteoOrOther = (job: JobKeyType) => {
    return job === "osteo" || job === "other"
  }

  return (
    <Box flex={1} flexDirection="column">
      <Heading as="h1" fontSize={28} color="tertiary.500" mb={8}>
        Consultations récentes
      </Heading>

      {formattedList.length === 0 && !isLoading && (
        <Box
          flex={1}
          mt={20}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <NoAppointmentYet />
        </Box>
      )}
      {formattedList.length !== 0 && (
        <Box>
          <Box my={6}>
            <OsteoFilter
              selectedItems={selectedOsteos}
              setSelectedItems={setSelectedOsteos}
            />
          </Box>
          <TableContainer>
            <Table size="lg" background="white" borderRadius={10}>
              <Thead>
                <Tr>
                  <Th
                    color="common.700"
                    textTransform="none"
                    fontFamily="Mulish"
                    textAlign="center"
                  >
                    Jour
                  </Th>

                  <Th
                    color="common.700"
                    textTransform="none"
                    fontFamily="Mulish"
                    textAlign="center"
                  >
                    Nom
                  </Th>

                  <Th
                    color="common.700"
                    textTransform="none"
                    fontFamily="Mulish"
                    textAlign="center"
                  >
                    Prénom
                  </Th>
                  {currentScope?.id && (
                    <Th
                      color="common.700"
                      textTransform="none"
                      fontFamily="Mulish"
                      textAlign="center"
                    >
                      Vu par
                    </Th>
                  )}
                  <Th
                    color="common.700"
                    textTransform="none"
                    fontFamily="Mulish"
                    textAlign="center"
                  >
                    Consultations
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {formattedList
                  ?.filter((item: AppointmentSimplifiedType) => item.patient)
                  ?.sort((a, b) =>
                    isBefore(new Date(a.createdAt), new Date(b.createdAt))
                      ? 1
                      : -1
                  )
                  .map((item: AppointmentSimplifiedType) => (
                    <Tr key={item.id}>
                      <Td
                        fontSize={14}
                        borderBottom="1px solid"
                        borderColor="gray.300"
                        textAlign="center"
                      >
                        {item.dateFormatted}
                      </Td>
                      <Td
                        fontSize={14}
                        borderBottom="1px solid"
                        borderColor="gray.300"
                        textAlign="center"
                      >
                        {item.patient.lastname}
                      </Td>
                      <Td
                        fontSize={14}
                        borderBottom="1px solid"
                        borderColor="gray.300"
                        textAlign="center"
                      >
                        {item.patient.firstname}
                      </Td>
                      {currentScope?.id && (
                        <Td
                          fontSize={14}
                          borderBottom="1px solid"
                          borderColor="gray.300"
                          textAlign="center"
                        >
                          {item.osteo.firstname} {item.osteo.lastname}
                        </Td>
                      )}
                      <Td
                        fontSize={14}
                        borderBottom="1px solid"
                        borderColor="gray.300"
                        textAlign="center"
                      >
                        {isAppointmentOsteoOrOther(item.job) && isJobOsteo ? (
                          <Link
                            px={4}
                            py={2}
                            as={RouterLink}
                            to={`/patients/${item.patient.id}/appointments/${item.id}`}
                            _hover={{
                              textDecoration: "none",
                            }}
                          >
                            <AButton
                              variant="secondary"
                              text="Voir la consultation"
                              fontWeight={400}
                            />
                          </Link>
                        ) : isAppointmentOsteoOrOther(item.job) ? (
                          <Link
                            px={4}
                            py={2}
                            as={RouterLink}
                            to={`/patients/${item.patient.id}/treatment/-1/appointment/${item.id}`}
                            _hover={{
                              textDecoration: "none",
                            }}
                          >
                            <AButton
                              variant="secondary"
                              text="Voir la consultation"
                              fontWeight={400}
                            />
                          </Link>
                        ) : (
                          <Link
                            px={4}
                            py={2}
                            as={RouterLink}
                            to={`/patients/${item.patient.id}/treatment/${item.treatment?.id}/appointment/${item.id}`}
                            _hover={{
                              textDecoration: "none",
                            }}
                          >
                            <AButton
                              variant="secondary"
                              text="Voir la consultation"
                              fontWeight={400}
                            />
                          </Link>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          {!isLoading && (
            <ATablePagination
              pageCount={pageCount}
              onPageChange={setPage}
              page={page}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default RecentAppointments
