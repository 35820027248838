import { useAppContext } from "AppContext"

const useJobIsOsteo = () => {
  const { user } = useAppContext()
  const osteoJobKey = "osteo"
  const otherJobKey = "other" //Consider other job as osteo in terms of features

  return user?.osteo?.jobs?.some(
    (job) => job.key === osteoJobKey || job.key === otherJobKey
  )
}

export default useJobIsOsteo
