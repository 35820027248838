import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import AndrewFlying from "assets/illustrations/andrew-flying.png"
import AButton from "components/AButton"
import { useNavigate } from "react-router-dom"
const NoAppointmentYet = () => {
  const navigate = useNavigate()

  return (
    <Flex
      direction="column"
      justifyContent="center"
      width={800}
      height={400}
      borderRadius={10}
      px={12}
      backgroundColor={"primary.150"}
      position="relative"
    >
      <Box maxWidth={400}>
        <Heading as="h1" fontSize={18} color="tertiary.500">
          Démarrez l’aventure Andrew® !
        </Heading>
        <Text fontSize={16} color="common.700" mt={4}>
          Accompagnez vos patients en quelques clics, prescrivez des exercices
          personnalisés qu'ils pourront suivre sur leur mobile, analysez leurs
          progrès en temps réel, et optimisez leur traitement grâce aux données
          de suivi.
        </Text>
      </Box>

      <Box>
        <AButton
          text="Accompagner mon premier patient"
          mt={42}
          onClick={() => navigate("/patients/new")}
        />
      </Box>

      <Image
        position="absolute"
        right={30}
        top={30}
        w={216}
        h={126}
        src={AndrewFlying}
        alt="Andrew Aventurier"
      />
    </Flex>
  )
}

export default NoAppointmentYet
