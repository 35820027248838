import {
  Box,
  Flex,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import {
  getScheduledGroupMessages,
  ScheduledGroupMessageType,
} from "api/scheduledGroupMessage"
import { useAppContext } from "AppContext"
import AButton from "components/AButton"
import ATableEmpty from "components/Table/ATableEmpty"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"

const Messages = () => {
  const { currentScope } = useAppContext()
  const [messages, setMessages] = useState<
    ScheduledGroupMessageType[] | undefined
  >(undefined)

  const fetchMessages = async () => {
    const fetchedMessages = await getScheduledGroupMessages(
      currentScope?.type === "center" ? currentScope.id : undefined
    )
    const sortedMessages = fetchedMessages.sort(
      (a, b) =>
        new Date(b.sendDateTime).valueOf() - new Date(a.sendDateTime).valueOf()
    )
    setMessages(sortedMessages)
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  return (
    <Flex direction="column" gap={10}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h1" fontSize={28} color="tertiary.500">
            Messages
          </Heading>
          <Text fontSize={16} color="common.700" pt={4}>
            Diffusez à tous vos patients une information relative à votre
            cabinet.
          </Text>
        </Box>
        <Link as={RouterLink} to="/messages/new">
          <AButton text="Nouveau message" />
        </Link>
      </Flex>
      <TableContainer>
        <Table size="lg" background="white" borderRadius={10}>
          <Thead>
            <Tr>
              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="left"
                minW={52}
              >
                Date d'envoi
              </Th>

              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="left"
                minW={56}
              >
                Titre
              </Th>
              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="left"
                minW={203}
              >
                Statut
              </Th>
              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="left"
                aria-label="Editer"
                minW={10}
              />
            </Tr>
          </Thead>
          <Tbody>
            {messages === undefined || messages.length === 0 ? (
              <ATableEmpty
                colSpan={5}
                fontSize={14}
                border="none"
                textAlign="center"
              />
            ) : (
              messages.map((message) => (
                <Tr key={message.id} border="none">
                  <Td fontSize={14} border="none" textAlign="left">
                    {format(new Date(message.sendDateTime), "yyyy-MM-dd")}
                  </Td>
                  <Td fontSize={14} border="none" textAlign="left">
                    {message.title}
                  </Td>
                  <Td fontSize={14} border="none" textAlign="left">
                    {message.isSent ? "Envoyé" : "En attente"}
                  </Td>
                  {!message.isSent && (
                    <Td fontSize={14} border="none" textAlign="left">
                      <Link as={RouterLink} to={`/messages/edit/${message.id}`}>
                        <AButton text="Editer" variant="tertiary" />
                      </Link>
                    </Td>
                  )}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

export default Messages
