import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  useDisclosure,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { format } from "date-fns"
import { Field, Form, Formik } from "formik"
import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { checkPatientEmailExists, createPatient } from "../../api/patient"
import arrowBack from "../../assets/arrow_back.svg"
import AButton from "../../components/AButton"
import useToast from "../../hooks/useToast"
// import { verifyEmail } from "../../api/user"
import { CreatePatientType } from "api/patient"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import CreateNoVerifiedEmailPatient from "pages/NewPatient/component/CreateNoVerifiedEmailPatient"
import DiscloseNewPatientTempPassword from "pages/NewPatient/component/DiscloseNewPatientTempPassword"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import uppercaseFirstLetterLowercaseRest from "utils/uppercaseFirstLetterLowercaseRest"
import { useAppContext } from "../../AppContext"
import getMeQueryKeys from "../../constants/queryKeys/getMe"
import parseBirthday from "../../utils/parseBirthday"
import InvitePatient from "./component/InvitePatient"

interface Step2FormValues {
  lastname: string
  firstname: string
  birthday: string
  telephone: string
  postalCode: string
}

const NewPatient = () => {
  const queryClient = useQueryClient()
  const { user, jwt, currentScope } = useAppContext()
  const createPatientExtraParams = getCurrentScopeParams(currentScope)
  const createPatientMutation = useMutation(createPatient)
  const checkPatientEmailExistsMutation = useMutation(checkPatientEmailExists)
  const navigate = useNavigate()
  const [typedEmail, setTypedEmail] = useState<string>("")
  const [tempPassword, setTempPassword] = useState<string>("")
  const [patientId, setPatientId] = useState<number>()
  const [tempPatient, setTempPatient] = useState<CreatePatientType>()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: passwordIsOpen,
    onOpen: passwordOnOpen,
    onClose: passwordOnClose,
  } = useDisclosure()

  const {
    isOpen: noVerifiedEmailIsOpen,
    onOpen: noVerifiedEmailOnOpen,
    onClose: noVerifiedEmailOnClose,
  } = useDisclosure()
  const cancelRefNoVerifiedEmail = useRef(null)
  const cancelRefPassword = useRef(null)
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const isJobKineOrPodo = useJobIsKineOrPodo()
  const [step, setStep] = useState<1 | 2>(1)
  const [emailValue, setEmailValue] = useState("")
  const [step2Values, setStep2Values] = useState<Step2FormValues>({
    lastname: "",
    firstname: "",
    birthday: "",
    telephone: "",
    postalCode: "",
  })

  const onPatientSuccess = ({
    id,
    password,
  }: {
    id: number
    password: string
  }) => {
    setPatientId(id)
    setTempPassword(password)
    passwordOnOpen()
  }

  const onAndrewPlusPatientSuccess = ({ id }: { id: number }) => {
    setPatientId(id)
    toast({
      status: "success",
      title: "Patient ajouté avec succès",
    })
    if (isJobKineOrPodo) {
      navigate(`/patients/${id}/appointments/new/treament/0`)
    } else {
      navigate(`/patients/${id}/appointments/new`)
    }
  }

  const passwordOnCloseNavigate = () => {
    passwordOnClose()
    if (isJobKineOrPodo) {
      navigate(`/patients/${patientId}/appointments/new/treament/0`)
    } else {
      navigate(`/patients/${patientId}/appointments/new`)
    }
  }

  const onFixEmailTapped = () => {
    setStep(1)
    noVerifiedEmailOnClose()
  }

  /**
   * Handle the email submission on first step. Will check if the email exists or if the user already has access to the patient.
   * @param values - The form values
   * @param param1 - The formik props
   */
  const handleEmailSubmit = async (
    values: { email: string },
    { setSubmitting }: any
  ) => {
    const email = values.email.trim()
    setEmailValue(email)
    setTypedEmail(email)

    try {
      // Vérification si le patient existe déjà
      await checkPatientEmailExistsMutation.mutateAsync(
        { email, ...createPatientExtraParams },

        {
          onSuccess: (data) => {
            if (data.exists && !data.canAccess) {
              //User already exists but does not have access to the patient. Show the error message
              onOpen()
              return
            }
            if (data.exists && data.canAccess && data.path) {
              //User already exists and can access the patient. Redirect to the patient profile
              navigate(data.path)
              return
            }
            setStep(2)
          },
          onError: (data: any) => {
            setSubmitting(false)
            // onCollaboratorUnauthorized(data)
            const message = data.response.data.error.message
            toast({
              status: "error",
              title: message,
            })
          },
        }
      )
    } catch (error) {
      // Gestion d'erreur déjà incluse dans onError
    } finally {
      setSubmitting(false)
    }
  }

  /**
   * Handle the account creation submission on second step when patient does not already exists. Will create the patient and redirect to the patient profile.
   * @param values - The form values
   * @param param1 - The formik props
   */
  const handleAccountCreationSubmit = (values: any, { setSubmitting }: any) => {
    if (!user || !user.osteo) return
    const newBirthday = parseBirthday(values.birthday)

    // Sauvegarder les valeurs avant de soumettre
    setStep2Values({
      lastname: values.lastname,
      firstname: values.firstname,
      birthday: values.birthday,
      telephone: values.telephone,
      postalCode: values.postalCode,
    })

    const patientData = {
      firstname: values.firstname,
      lastname: values.lastname,
      telephone: values.telephone?.split(" ").join(""),
      postalCode: values.postalCode,
      birthday: format(newBirthday, "yyyy-MM-dd"),
      email: emailValue,
      osteo: user.osteo.id,
      ...createPatientExtraParams,
    }
    createPatientMutation.mutate(patientData, {
      onSuccess: (data) => {
        onAddPatientSuccess({ id: data.id, password: data.temp_password })
      },
      onError: (data: any) => {
        setSubmitting(false)
        onCollaboratorUnauthorized(data)
        const errorName = data.response.data.error.name
        if (errorName === "BadEmailError") {
          setTempPatient(patientData)
          noVerifiedEmailOnOpen()
        } else if (errorName === "WrongRoleError") {
          toast({
            status: "error",
            title:
              "Ce mail est déjà associé à un compte hors patient déjà existant",
          })
        } else {
          toast({
            status: "error",
            title: data.response.data.error.message,
          })
        }
      },
    })
  }

  const onAddPatientSuccess = ({
    id,
    password,
  }: {
    id?: number
    password?: string
  }) => {
    if (!id) return
    queryClient.invalidateQueries({
      queryKey: getMeQueryKeys.me(jwt!),
    })

    //Display temp password if available
    if (password !== undefined) {
      onPatientSuccess({
        id: id,
        password: password,
      })
      return
    }

    onAndrewPlusPatientSuccess({ id: id })
  }

  return (
    <Box flex={1} p="16px">
      <Box
        as="button"
        onClick={() => navigate(-1)} //To act like a navigator back action.
        p="8px"
        bgColor="primary.200"
        w="fit-content"
        display="block"
        rounded="9px"
      >
        <Image src={arrowBack} width="32px" height="24px" />
      </Box>
      <Flex justifyContent="center" mt={7}>
        <Flex direction="column" maxW="640px" w="100%">
          <Heading as="h1" fontSize={28} color="black" mb={6}>
            Nouveau patient
          </Heading>

          <Formik
            initialValues={{ email: emailValue }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Ceci n'est pas un email")
                .required("Ce champ est obligatoire"),
            })}
            onSubmit={handleEmailSubmit}
          >
            {(props) => (
              <Form>
                <Field name="email">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                      mb={6}
                    >
                      <FormLabel fontWeight={700}>Email *</FormLabel>
                      <Input
                        {...field}
                        placeholder="exemple@exemple.com"
                        disabled={step === 2}
                        bg="white"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {step === 1 && (
                  <AButton
                    text="Suivant"
                    px={24}
                    py={3}
                    type="submit"
                    isLoading={props.isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
          {step === 2 && (
            <Formik
              initialValues={{
                lastname: step2Values.lastname,
                firstname: step2Values.firstname,
                birthday: step2Values.birthday,
                telephone: step2Values.telephone,
                postalCode: step2Values.postalCode,
                email: emailValue,
              }}
              validateOnChange={true}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                lastname: Yup.string().required("Ce champ est obligatoire"),
                firstname: Yup.string().required("Ce champ est obligatoire"),
                birthday: Yup.date()
                  .required("Ce champ est obligatoire")
                  .transform(function (value, originalValue) {
                    return parseBirthday(originalValue)
                  })
                  .typeError("Merci de rentrer une date valide"),
                telephone: Yup.string().matches(
                  new RegExp(
                    /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
                  ),
                  "Ceci n'est pas un numéro de téléphone"
                ),
                postalCode: Yup.string(),
              })}
              onSubmit={handleAccountCreationSubmit}
            >
              {(props) => (
                <Form
                  onChange={() => {
                    // Sauvegarder les valeurs à chaque changement
                    setStep2Values({
                      lastname: props.values.lastname,
                      firstname: props.values.firstname,
                      birthday: props.values.birthday,
                      telephone: props.values.telephone,
                      postalCode: props.values.postalCode,
                    })
                  }}
                >
                  <Flex gap={26}>
                    <Field name="lastname">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.lastname && form.touched.lastname
                          }
                          flex={1}
                          mb={6}
                        >
                          <FormLabel fontWeight={700}>Nom *</FormLabel>
                          <Input
                            {...field}
                            placeholder="Nom"
                            bg="white"
                            onChange={(e) => {
                              e.target.value = e.target.value.toUpperCase()
                              field.onChange(e)
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.lastname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="firstname">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.firstname && form.touched.firstname
                          }
                          flex={1}
                          mb={6}
                        >
                          <FormLabel fontWeight={700}>Prénom *</FormLabel>
                          <Input
                            {...field}
                            placeholder="Prénom"
                            bg="white"
                            onChange={(e) => {
                              e.target.value =
                                uppercaseFirstLetterLowercaseRest(
                                  e.target.value
                                )
                              field.onChange(e)
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.firstname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Flex gap={26}>
                    <Field name="birthday">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.birthday && form.touched.birthday
                          }
                          w="calc(50% - 12px)"
                          mb={6}
                        >
                          <FormLabel fontWeight={700}>
                            Date de naissance *
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="01/01/2001"
                            bg="white"
                            type="string"
                            onChange={(e) => {
                              if (
                                (e.target.value.length === 2 &&
                                  field.value.length !== 3) ||
                                (e.target.value.length === 5 &&
                                  field.value.length !== 6)
                              ) {
                                e.target.value += "/"
                              }
                              field.onChange(e)
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.birthday}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Flex gap={26}>
                    <Field name="telephone">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.telephone && form.touched.telephone
                          }
                          w="calc(50% - 12px)"
                          mb={6}
                        >
                          <FormLabel fontWeight={700}>Téléphone</FormLabel>
                          <Input
                            {...field}
                            placeholder="06 12 34 56 78"
                            bg="white"
                            onKeyDown={(e) => {
                              if (
                                e.key === "Backspace" ||
                                e.key === "Delete" ||
                                e.key === "ArrowLeft" ||
                                e.key === "ArrowRight" ||
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown" ||
                                e.key === "Tab" ||
                                e.key === "Enter" ||
                                e.key === "Control" ||
                                e.key === "Command" ||
                                e.key === "+" ||
                                e.key === " " ||
                                // Paste event
                                (e.key === "v" && e.ctrlKey === true) ||
                                // Paste event on mac
                                (e.key === "v" && e.metaKey === true) ||
                                // Select all event
                                (e.key === "a" && e.ctrlKey === true) ||
                                // Select all event on mac
                                (e.key === "a" && e.metaKey === true) ||
                                // Cut event
                                (e.key === "x" && e.ctrlKey === true) ||
                                // Cut event on mac
                                (e.key === "x" && e.metaKey === true)
                              ) {
                                return
                              }
                              const isNumber = /^[0-9]$/i.test(e.key)
                              if (!isNumber) {
                                e.preventDefault()
                              }
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.telephone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="postalCode">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.postalCode && form.touched.postalCode
                          }
                          w="calc(50% - 12px)"
                          mb={16}
                        >
                          <FormLabel fontWeight={700}>Code postal</FormLabel>
                          <Input
                            {...field}
                            placeholder="69003"
                            bg="white"
                            maxLength={5}
                            onKeyDown={(e) => {
                              if (
                                e.key === "Backspace" ||
                                e.key === "Delete" ||
                                e.key === "ArrowLeft" ||
                                e.key === "ArrowRight" ||
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown" ||
                                e.key === "Tab" ||
                                e.key === "Enter" ||
                                e.key === "Control" ||
                                e.key === "Command" ||
                                // Paste event
                                (e.key === "v" && e.ctrlKey === true) ||
                                // Paste event on mac
                                (e.key === "v" && e.metaKey === true) ||
                                // Select all event
                                (e.key === "a" && e.ctrlKey === true) ||
                                // Select all event on mac
                                (e.key === "a" && e.metaKey === true) ||
                                // Cut event
                                (e.key === "x" && e.ctrlKey === true) ||
                                // Cut event on mac
                                (e.key === "x" && e.metaKey === true)
                              ) {
                                return
                              }
                              if (e.currentTarget.value.length >= 14) {
                                e.preventDefault()
                              }
                              const isNumber = /^[0-9]$/i.test(e.key)
                              if (!isNumber) {
                                e.preventDefault()
                              }
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.postalCode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <DiscloseNewPatientTempPassword
                    onClose={passwordOnCloseNavigate}
                    isOpen={passwordIsOpen}
                    password={tempPassword}
                  />
                  <Flex>
                    <AButton
                      text="Retour"
                      py={3}
                      px={12}
                      variant="tertiary"
                      isLoading={props.isSubmitting}
                      onClick={() => setStep(1)}
                    />
                    <AButton
                      text="Inviter"
                      py={3}
                      px={24}
                      ml={8}
                      type="submit"
                      isLoading={props.isSubmitting}
                    />
                  </Flex>
                </Form>
              )}
            </Formik>
          )}
        </Flex>
      </Flex>
      <InvitePatient
        onClose={onClose}
        isOpen={isOpen}
        cancelRef={cancelRefPassword}
        email={typedEmail}
      />
      <CreateNoVerifiedEmailPatient
        onClose={noVerifiedEmailOnClose}
        shouldFixEmail={onFixEmailTapped}
        isOpen={noVerifiedEmailIsOpen}
        cancelRef={cancelRefNoVerifiedEmail}
        patient={tempPatient}
        onPatientSuccess={onAddPatientSuccess}
      />
    </Box>
  )
}

export default NewPatient
