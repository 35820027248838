import { Box, Flex, Heading, Spinner } from "@chakra-ui/react"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  AppointmentReasonTypePaginated,
  getAppointmentReason,
  GetAppointmentReasonParams,
  getAppointmentReasons,
  getLikedVideosForAppointmentReason,
  getUploadedVideosForAppointmentReason,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import { getCommonPrograms, getMyPrograms, ProgramType } from "api/program"
import AUploadedExerciceCard from "components/AUploadedExerciceCard/AUploadedExerciceCard"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"
import FilterPrograms from "pages/Exercices/components/ExercicesLists/components/FilterPrograms/FilterPrograms"
import ProgramCard from "pages/Exercices/components/ExercicesLists/components/ProgramCard/ProgramCard"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import AddProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddProgramModal"
import {
  getZonesAndPathos,
  ZonePathologieType,
} from "pages/NewAppointment/newAppointment.mock"
import {
  sortByJob,
  sortByTimeUsed,
} from "pages/NewProgram/components/SelectProgramExercices"
import React, { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"
import VideoGroup from "./components/VideoGroup/VideoGroup"

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  reason?: ZonePathologieType
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
  isForKineOrPodo?: boolean
}

const ExercicesLists: React.FC<IProps> = ({
  reason,
  reasonType,
  setReasonType,
  setReason,
  isForKineOrPodo,
}) => {
  const [search, setSearch] = useState("")
  const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)
  const isJobKineOrPodo = useJobIsKineOrPodo()
  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null
  )

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)
  const [sortedCommonPrograms, setSortedCommonPrograms] = useState<
    ProgramType[]
  >([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [appointmentReason, setAppointmentReason] = useState<
    undefined | AppointmentReasonTypePaginated
  >(undefined)

  const [selectedClinicalPicture, setSelectedClinicalPicture] = useState<
    string | null
  >(null)

  const [debouncedSearch] = useDebounce(search, 500)
  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
      search: debouncedSearch,
    })
  }, [debouncedSearch])

  const { mutate, isLoading: isAppointmentReasonLoading } = useMutation({
    mutationKey: ["appointmentReason", reason?.id, 1],
    mutationFn: (params: GetAppointmentReasonParams) =>
      getAppointmentReason(params),
    onSuccess: (response) => {
      if (reason?.id) {
        setAppointmentReason(response.data)
      } else {
        setAppointmentReason(undefined)
      }
      setVideoGroups(
        niceDisplayVideoGroup(
          response.data.trainingVideoGroups.sort(
            isJobKineOrPodo ? sortByJob : sortByTimeUsed
          )
        )
      )

      setPageCount(response.data.pagination.pageCount)
    },
  })

  const { data: uploadedVideos } = useQuery({
    queryKey: ["uploadedVideos", reason?.id ?? -1],
    queryFn: () => getUploadedVideosForAppointmentReason(reason?.id ?? -1),
  })

  const { data: likedVideos } = useQuery({
    queryKey: ["likedVideos", reason?.id ?? -1],
    queryFn: () => getLikedVideosForAppointmentReason(reason?.id ?? -1),
  })

  // Effet pour déclencher la mutation quand les params changent
  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
    })
  }, [reason?.id])
  useEffect(() => {
    mutate({
      reasonId: reason?.id ?? -1,
      page,
    })
  }, [page])

  useEffect(() => {
    if (reason?.id) setPageCount(0)
  }, [reason?.id])

  const { data: allAppointmentReasons } = useQuery({
    queryKey: ["appointmentReasons"],
    queryFn: getAppointmentReasons,
  })
  const { data: programs } = useQuery({
    queryKey: ["getMyPrograms"],
    queryFn: getMyPrograms,
  })
  const { data: commonPrograms } = useQuery({
    queryKey: ["getCommonPrograms"],
    queryFn: getCommonPrograms,
  })

  useEffect(() => {
    setSortedFilteredVideoGroups(
      filterWithCategoriesRestrictionsAndSearch(videoGroups)
    )
  }, [
    videoGroups,
    categoryFilter,
    restrictionFilter,
    search,
    selectedClinicalPicture,
  ])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs ?? [])
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  useEffect(() => {
    const filteredProgram = filterPrograms(commonPrograms ?? [])
    setSortedCommonPrograms(filteredProgram)
  }, [search, reason, commonPrograms])

  const filteredUploadedVideo = uploadedVideos?.filter((video) =>
    video.title.toLowerCase().includes(search.toLowerCase())
  )

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    if (selectedClinicalPicture !== null) {
      filteredList = filteredList.filter(
        ({ clinicalPictures }) =>
          clinicalPictures?.some(
            ({ id }) => id === Number(selectedClinicalPicture)
          ) ?? false
      )
    }
    return filteredList
  }

  const filterPrograms = (list: ProgramType[]) => {
    const newList = list.filter(
      (program) =>
        program.trainingVideoPrograms &&
        program.trainingVideoPrograms.length > 0
    )
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = newList
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (reason) {
      filteredList = filteredList.filter(({ appointmentReasons }) =>
        appointmentReasons.find(
          (currentReason) => currentReason.id === reason.id
        )
      )
    }
    return filteredList
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [sortedFilteredPrograms, setSortedFilteredPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    setSortedFilteredVideoGroups(
      filterWithCategoriesRestrictionsAndSearch(videoGroups)
    )
  }, [
    videoGroups,
    categoryFilter,
    restrictionFilter,
    search,
    selectedClinicalPicture,
  ])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs ?? [])
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  const hideClinicalPictures = selectedTab === 1

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  return (
    <Flex direction="column" gap="16px" position="relative" width="full">
      <TabExercices
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isPageExercies
        isForKineOrPodo={isForKineOrPodo}
        appointmentReason={reason?.id}
      />

      {/* <Flex gap="93px"> */}
      <Flex gap={"10px"}>
        <Box flex={1} position="relative">
          {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          )}

          {selectedTab === 1 && <FilterPrograms setSearch={setSearch} />}

          <Flex justifyContent="flex-start" flexWrap="wrap" gap="16px">
            {selectedTab === 0 &&
              (isAppointmentReasonLoading ? (
                <Flex
                  flexDir={"column"}
                  alignItems="center"
                  w="full"
                  h="calc(100vh - 200px)"
                  justifyContent="center"
                >
                  <Spinner color="primary.500" />
                </Flex>
              ) : (
                <Flex flexDir={"column"} gap={"16px"} w="full" mt={8}>
                  {uploadedVideos?.length && uploadedVideos?.length > 0 ? (
                    <Box>
                      <Heading
                        as="h6"
                        fontSize="18px"
                        lineHeight="100%"
                        flex={1}
                        p={4}
                        my={4}
                        backgroundColor={"primary.200"}
                        borderRadius={"4px"}
                      >
                        Vos exercices
                      </Heading>
                      <Flex
                        justifyContent="space-between"
                        flexWrap="wrap"
                        gap="16px"
                        w="full"
                      >
                        {uploadedVideos.map((video, i) => (
                          <AUploadedExerciceCard
                            key={`uploaded_video_${video.id}`}
                            video={video}
                            appointmentReason={reason?.id}
                          />
                        ))}
                      </Flex>
                    </Box>
                  ) : null}
                  <Box w="full">
                    {uploadedVideos?.length &&
                    uploadedVideos?.length > 0 &&
                    sortedFilteredVideoGroups.length > 0 ? (
                      <Heading
                        as="h6"
                        fontSize="18px"
                        lineHeight="100%"
                        flex={1}
                        p={4}
                        my={4}
                        backgroundColor={"primary.200"}
                        borderRadius={"4px"}
                      >
                        {" "}
                        Autres exercices
                      </Heading>
                    ) : null}
                    <Flex
                      justifyContent="space-between"
                      flexWrap="wrap"
                      gap="16px"
                      w="full"
                    >
                      {sortedFilteredVideoGroups.map((videoGroup, i) => (
                        <VideoGroup
                          key={`group_${videoGroup.id}_${i}`}
                          videoGroup={videoGroup}
                          isLiked={
                            likedVideos?.some(
                              (likedVideo) => likedVideo.id === videoGroup.id
                            ) ?? false
                          }
                        />
                      ))}
                    </Flex>
                    {selectedTab === 0 && (
                      <Box mt={4}>
                        <ATablePagination
                          pageCount={pageCount}
                          onPageChange={setPage}
                          page={page}
                        />
                      </Box>
                    )}
                  </Box>
                </Flex>
              ))}
            {selectedTab === 1 && (
              <Box w="full" pb={16}>
                {sortedFilteredPrograms.length > 0 ? (
                  <Box pb={16}>
                    <Heading
                      as="h6"
                      fontSize="18px"
                      lineHeight="100%"
                      flex={1}
                      p={4}
                      backgroundColor={"primary.200"}
                      borderRadius={"4px"}
                      my={4}
                    >
                      Vos programmes
                    </Heading>
                    <Flex
                      justifyContent="space-between"
                      flexWrap="wrap"
                      gap="1%"
                      pt={4}
                    >
                      {sortedFilteredPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          isLooking
                        />
                      ))}
                    </Flex>
                  </Box>
                ) : null}
                {sortedCommonPrograms.length > 0 ? (
                  <Box>
                    <Heading
                      as="h6"
                      fontSize="18px"
                      lineHeight="100%"
                      flex={1}
                      backgroundColor={"primary.200"}
                      borderRadius={"4px"}
                      p={4}
                    >
                      Autres programmes
                    </Heading>
                    <Flex
                      justifyContent="space-between"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedCommonPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          isLooking
                        />
                      ))}
                    </Flex>
                  </Box>
                ) : null}
              </Box>
            )}

            {selectedTab === 2 &&
              likedVideos?.map((videoGroup, i) => (
                <VideoGroup
                  key={`liked_group_${videoGroup.id}_${i}`}
                  videoGroup={videoGroup}
                  reason={reason}
                  isLiked
                />
              ))}
          </Flex>
        </Box>
        <Flex direction="column" pos="sticky" top={4} pl="24px" h="fit-content">
          <Box
            position="absolute"
            h="calc(100vh)"
            left="0px"
            w="1px"
            top="-16px"
            bg="primary.200"
          ></Box>
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              <ZonesPathologies
                datas={getZonesAndPathos(allAppointmentReasons)}
                selectedData={reason}
                isForKineOrPodo={isForKineOrPodo}
                setSelectedData={setReason}
                reason={appointmentReason}
                hideClinicalPictures={hideClinicalPictures}
                selectedClinicalPicture={selectedClinicalPicture}
                setSelectedClinicalPicture={setSelectedClinicalPicture}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {selectedProgram && (
        <AddProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
      )}
    </Flex>
  )
}

export default ExercicesLists
